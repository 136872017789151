import { ReactElement } from "react";
import { NotFound } from "@newt/ui";
import { Head } from "@components/common";
import { NextPageWithLayout } from "@root/types";
import { BasicLayout } from "@components/layout";
import { graphql } from "@utils/graphql";
import { GetStaticProps } from "next";
import { destinationsQuery } from "@graphql/types";

interface PageStaticProps {
  destinations: destinationsQuery;
}

export const getStaticProps: GetStaticProps<PageStaticProps> = async () => {
  const destinations = await graphql.destinations();

  return {
    props: {
      destinations,
    },
  };
};

const Page: NextPageWithLayout = () => {
  return (
    <>
      <Head title="404 Not Found" noindex={true} />
      <NotFound />
    </>
  );
};

Page.getLayout = (page: ReactElement<PageStaticProps>) => (
  <BasicLayout
    isWebview={false}
    destinations={page.props.destinations?.destinations}
  >
    {page}
  </BasicLayout>
);

export default Page;
